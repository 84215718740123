<template>
  <main>
    <section class="media-list">
    </section>
    <h2>Media</h2>
    <VueSlickCarousel v-bind="slickOptions" class="media">
            <article>
        <div class="list-image">
          <a href="#">
            <img
              src="https://i0.wp.com/innovation-village.com/wp-content/uploads/2020/08/AppOfTheYear_Approved-Logo.jpg?fit=1000%2C600&ssl=1"
              alt=""
            />
          </a>
        </div>
      </article>
      <article>
        <div class="list-image">
          <a href="#">
            <img
              src="https://goodnewsdaily.co.za/good-news/2020/03/good-news-daily-logo_320x100.png"
              alt=""
            />
          </a>
        </div>
      </article>
      <article>
        <div class="list-image">
          <a href="#">
            <img
              src="https://www.heraldlive.co.za/publication/custom/static/logos/logo.herald-live.png"
              alt=""
            />
          </a>
        </div>
      </article>
      <article>
        <div class="list-image">
          <a href="#">
            <img
              src="https://www.itweb.co.za/static/icons/itweb_logo.png"
              alt=""
            />
          </a>
        </div>
      </article>
      <article>
        <div class="list-image">
          <a href="#">
            <img
              src="https://static.iono.fm/files/p782/logo_628689_20191029_235811_600.jpg"
              alt=""
            />
          </a>
        </div>
      </article>
      <article>
        <div class="list-image">
          <a href="#">
            <img
              src="https://journalism.co.za/wp-content/uploads/2018/07/news-24-logo-article_765x0_80.jpg"
              alt=""
            />
          </a>
        </div>
      </article>
      <article>
        <div class="list-image">
          <a href="#">
            <img
              src="https://pbs.twimg.com/profile_images/840946985297420289/S_kxeJq4.jpg"
              alt=""
            />
          </a>
        </div>
      </article>
      <article>
        <div class="list-image">
          <a href="#">
            <img
              src="https://upload.wikimedia.org/wikipedia/en/d/d2/Metro_FM_%282013_Logo%29.gif"
              alt=""
            />
          </a>
        </div>
      </article>
    </VueSlickCarousel>
    
    <h2>Our Partners</h2>
    <VueSlickCarousel v-bind="slickOptions" class="partners">
        <article>
        <div class="list-image">
          <a href="#">
            <img
              src="@/assets/partners/Department-of-Basic-Education-Logo-Generic-scaled-1.jpeg"
          alt="basic education"
            />
          </a>
        </div>
      </article>
        <article>
        <div class="list-image-pt">
          <a href="#">
            <img
              src="https://cdn.da.org.za/wp-content/uploads/2020/11/25123232/images.png"
          alt="matric second chance programme"
            />
          </a>
        </div>
      </article>
      <article>
        <div class="list-image">
          <a href="#">
            <img
              src="https://nect.org.za/++theme++JuiziSkin/nect2017/images/logo.png"
          alt="nect"
            />
          </a>
        </div>
      </article>
        <article>
        <div class="list-image-pt">
          <a href="#">
            <img
               src="https://www.banking.org.za/wp-content/uploads/2019/06/logo.png"
          alt="basa"
            />
          </a>
        </div>
      </article>
      
      <article>
        <div class="list-image">
          <a href="#">
            <img
              src="@/assets/partners/woza-matrics.png"
          alt="woza matrics"
            />
          </a>
        </div>
      </article>
      <article>
        <div class="list-image">
          <a href="#">
            <img
              src="@/assets/partners/oldMutual.svg"
              alt="Old Mutual"
            />
          </a>
        </div>
      </article>
      
    </VueSlickCarousel>
  </main>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel/src/VueSlickCarousel.vue";

export default {
  components: { VueSlickCarousel },
  name: "Partners_And_Media",
  data() {
    return {
      slickOptions: {
          arrows: false,
        speed: 5000,
        autoplay: true,
        autoplaySpeed: 0,
        cssEase: "linear",
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        swipeToSlide: true,
        centerMode: true,
        responsive: [
          {
            breakpoint: 750,
            settings: {
              slidesToShow: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      },
    };
  },
};
</script>

<style scoped>
h2 {
  margin: 5% 0%;
}

.partners,
.media {
    margin: 0 2%;
}

.list-image {
  width: 88%;
  height: auto;
  margin: 3% 0%;
}

.list-image > a > img {
  /* width: 100%; */
  width: 80%;
}

.list-image-pt > a > img {
  /* width: 100%; */
  width: 80%;
}


@media screen and (max-width: 950px) {
  .media-list {
    display: block;
    justify-content: center;
  }

  .media-list article {
    margin-top: 5%;
    /* text-align: -webkit-center; */
  }

  .partners {
    /* display: block; */
    margin: 2%;
  }
  
}
</style>